<template>
  <v-container fluid>
    <h1>KPI: Delivery on time
      <ContextHelp
          helpText="This report shows the how well projects are being delivered on time.
        The KPI is calculated as the average number of days a project is delivered ahead of or behind the original due date. A negative number means we are behind schedule, a positive number means we are ahead of schedule.
        Calculation:
        * All jobs created in the last 365 days are loaded
        * Filter out jobs who which are completed, have the 'exclude' flag set, or don't have an original due date set
        * For each job, calculate the original project duration (start - original due date) and the actual duration (start - completed date)
        * Calculate the difference between the original and actual duration in days
        * Calculate the average difference in days"/>
    </h1>
    <SpeedFocusKPIGraph></SpeedFocusKPIGraph>
    <DeliveryOnTimeKPIGraph></DeliveryOnTimeKPIGraph>
  </v-container>
</template>

<script>
import SpeedFocusKPIGraph from "@/components/capacity/SpeedFocusKPIGraph.vue"

import DeliveryOnTimeKPIGraph from "@/components/capacity/DeliveryOnTimeKPIGraph.vue"
import ContextHelp from "@/components/toolkit/ContextHelp.vue";

export default {
  name: "ReportKPIPage",
  permissions: [...SpeedFocusKPIGraph.permissions, ...DeliveryOnTimeKPIGraph.permissions],
  components: {SpeedFocusKPIGraph, DeliveryOnTimeKPIGraph, ContextHelp},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>