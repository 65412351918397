<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="false"
                loaderType="list-item">

    <v-select label="Snapshot" :items="snapshots" v-model="selectedSnapshot" @change="loadSnapshot(selectedSnapshot)"></v-select>
    <div v-for="jobType in jobTypes" v-bind:key="jobType">
      <h2>{{ jobType }}</h2>
      <v-data-table class="service-table" dense hide-default-footer :headers="summaryHeaders" :items="serviceSummary[jobType]" :items-per-page="200">
        <template v-slot:item.name="{item}">
          <span :class="item._displayClass">{{ item.name }}</span>
        </template>
        <template v-slot:item.budget_time="{item}">
          <div>{{ Math.round(item.budget_time) }}</div>
        </template>
        <template v-slot:item.actual_time="{item}">
          <div>{{ Math.round(item.actual_time) }}</div>
          <div :class="itemClass(item.actualOver)">{{ Math.round(item.actualTimeDifference) }}</div>
        </template>

        <template v-slot:item.budget_money="{item}">
          <div>{{ item.budget_money | formatCurrency }}</div>
        </template>
        <template v-slot:item.actual_money="{item}">
          <div>{{ item.actual_money | formatCurrency }}</div>
          <div :class="itemClass(item.actualOver)">{{ item.budgetMoneyDifference | formatCurrency }}</div>
        </template>
        <template v-slot:item.invoiced_money="{item}">
          <div>{{ item.invoiced_money | formatCurrency }}</div>
          <div :class="itemClass(item.invoicedOver)">{{ item.invoicedMoneyDifference | formatCurrency }}</div>
        </template>
        <template v-slot:item.pcv="{item}">
          <div :class="itemClass(item.invoicedOver)">{{ Math.abs(item.pcv) }}%</div>
        </template>

      </v-data-table>
    </div>
    <p class="mt-3"><span class="green--text">Green</span> figures indicates under budget (good) &
      <span class="red--text">Red</span> figures indicates over budget (bad)</p>
    <ul>
      <li>PCV is calculated as (Actual-Invoiced)/Actual</li>
        <li>Actual overage is the difference between budget and actual</li>
        <li>Invoiced overage is the difference between actual and invoiced</li>
    </ul>
    <!-- used by the compiler -->
    <div class="heading text-red text-green display-none"></div>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {defineComponent} from "vue"


export default defineComponent({
  name: "AggregateProjectCostVariance",
  permissions: ["Administer BetaWorkflow"],
  props: ["months"],
  components: {
    BfwComponent
  },
  computed: {},
  mounted() {
    this.loadSnapshots().then(() => {
      this.isReady = true
    })
  },
  methods: {
    loadSnapshots() {
      return this.$BwfApi.get("project-cost-variance/snapshots").then(response => {
        const snapshots = []
        for (const snapshot of response) {
          snapshots.push({
            text: snapshot.substring(0, 10),
            value: snapshot
          })
        }

        // sort by value in descending order
        snapshots.sort((a, b) => {
          return a.value < b.value ? 1 : -1
        })
        this.snapshots = snapshots
      })
    },
    loadSnapshot(snapshot) {
      this.$BwfApi.get("project-cost-variance/snapshot", {snapshot: snapshot}).then(response => {
        const result = {} // {job_type: {billing_type: row}}}
        for (const row of response) {
          const jobType = row.job_type ? row.job_type : "Unknown"
          row.actualTimeDifference = Math.abs(row.actual_time - row.budget_time)
          row.budgetMoneyDifference = Math.abs(row.actual_money - row.budget_money)
          row.actualOver = (row.actual_money - row.budget_money) > 0
          row.invoicedMoneyDifference = Math.abs(row.actual_money - row.invoiced_money)
          row.invoicedOver = (row.actual_money - row.invoiced_money) > 0
          if (!result[jobType]) {
            result[jobType] = []
          }
          result[jobType].push(row)
        }
        this.serviceSummary = result
      })
    },
    itemClass(overUnder, name = "") {
      let classStr = ""
      if (overUnder !== null) {
        if (overUnder) classStr += "text-red "
        else classStr += "text-green "
      }

      if (name.endsWith("Total")) classStr += "total "
      if (name.endsWith("Grand Total")) classStr += "grand-total "
      return classStr
    },
  },
  data() {
    return {
      title: "Aggregate Project Cost Variance Report",
      showTitle: true,
      editable: false,
      isReady: false,
      selectedSnapshot: undefined,
      snapshots: [],
      data: {},
      jobTypes: ["Consulting", "Production"],
      summaryHeaders: [
        {text: "Service", value: "service_name"},
        {text: "Budget (hrs)", value: "budget_time", align: "right"},
        {text: "Actual (hrs)", value: "actual_time", align: "right"},
        {text: "Budget ($)", value: "budget_money", align: "right"},
        {text: "Actual ($)", value: "actual_money", align: "right"},
        {text: "Invoiced ($)", value: "invoiced_money", align: "right"},
        {text: "PCV (%)", value: "pcv", align: "right"},
      ],
      serviceSummary: []
    }
  },
})

</script>

<style scoped>
.total {
  font-weight: bold;
}

.text-red {
  color: red;
  font-weight: normal;
  font-style: italic;
}

.text-red:before {
  content: '(';
}

.text-red:after {
  content: ')';
}

.text-green {
  color: green;
  font-weight: normal;
  font-style: italic;
}

.grand-total {
  font-weight: bold;
  font-size: 1.2rem;
}

.service-table >>> td {
  vertical-align: top;
}


.service-table >>> tbody tr:last-child td,
.detail-table >>> tbody tr:last-child td {
  border-top: 1px solid #000;
  font-size: large;
}

.display-none {
  display: none;
}
</style>