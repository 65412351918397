<script>

export default {
  name: "RawReportData",
  permissions: ['Access Raw Reports Data'],
  methods: {
    loadAll() {
      return this.$BwfApi.get("raw-report-data").then(response => {
        this.data = response
      })
    },
    downloadFile(folderName) {
      if (this.selectedFile) {
        console.log(folderName, this.selectedFile)
        this.$BwfApi.get("raw-report-data/download", {folderName: folderName, reportName: this.selectedFile},
            {responseType: "blob"}
        ).then(response => {
          const objectUrl = window.URL.createObjectURL(response)
          const anchor = document.createElement("a")
          document.body.appendChild(anchor)
          anchor.target = "_blank"
          anchor.href = objectUrl
          anchor.download = this.selectedFile
          anchor.click()
        })
      }
    },
  },
  computed: {
    folderNames() {
      return Object.keys(this.data)
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      data: [],
      selectedFile: null,
    }
  },
}
</script>

<template>
  <v-container fluid>
    <h1>Raw Report Data</h1>
    <p>Download raw report data</p>
    <v-expansion-panels>
      <v-expansion-panel v-for="folderName in folderNames" :key="folderName">
        <v-expansion-panel-header>{{ folderName }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
              v-model="selectedFile"
              :items="data[folderName]"
              label="Raw Data"
              item-text="name"
              item-value="name"
              @input="downloadFile(folderName)"
          ></v-select>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<style scoped>

</style>