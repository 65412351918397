<script>

export default {
  name: "ContextHelp",
  props: {
    helpText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<template>

    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" fab x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Help</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark small @click="dialog = false" rounded>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="mt-3 pre-formatted">
         {{helpText}}
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<style scoped>
.pre-formatted {
  white-space: pre-line;
}
</style>