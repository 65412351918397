export default class Cashflow {

    job = undefined
    forecast = {}

    labourData = {}
    quotedLabourDollars = 0
    invoicedLabourDollars = 0

    quotedMaterialsCost = 0
    quotedMaterialsPrice = 0
    invoicedMaterialsCost = 0
    invoicedMaterialsPrice = 0

    constructor(vue) {
        this.vue = vue
    }

    setLabourData(data) {
        this.labourData = data
    }

    setForecast(data) {
        this.forecast = data
    }

    setInvoicedLabourDollars(dollars) {
        this.invoicedLabourDollars = dollars
    }

    setInvoicedMaterialsCost(dollars) {
        this.invoicedMaterialsCost = dollars
    }

    setInvoicedMaterialsPrice(dollars) {
        this.invoicedMaterialsPrice = dollars
    }

    setQuotedLabourDollars(dollars) {
        this.quotedLabourDollars = dollars
    }

    setQuotedMaterialsPrice(dollars) {
        this.quotedMaterialsPrice = dollars
    }

    setQuotedMaterialsCost(dollars) {
        this.quotedMaterialsCost = dollars
    }

    isHistorical(monthStr) {
        const parts = monthStr.split("-")
        const dataYear = parseInt(parts[0])
        const dataMonth = parseInt(parts[1])
        const now = new Date()
        const currentYear = now.getFullYear()
        const currentMonth = now.getMonth() + 1
        const c = currentYear * 12 + currentMonth
        const d = dataYear * 12 + dataMonth
        return d < c
    }

    previousMonthStr(currentMonthStr) {
        const parts = currentMonthStr.split("-")
        let dataYear = parseInt(parts[0])
        let dataMonth = parseInt(parts[1])
        dataMonth -= 1
        if (dataMonth === 0) {
            dataMonth = 12
            dataYear -= 1
        }
        return dataYear.toString() + "-" + dataMonth.toString().padStart(2, "0")
    }

    /**
     * Get the percentage of invoiced labour to date
     * @returns {number}
     */
    getInvoicedLabourPercentage() {
        if (this.quotedLabourDollars === 0 || this.invoicedLabourDollars === 0) {
            return 0
        }
        return 100 * this.invoicedLabourDollars / this.quotedLabourDollars
    }

    /**
     * Get the percentage of invoiced materials to date
     * @returns {number}
     */
    getInvoicedMaterialsPercentage() {
        if (this.quotedMaterialsPrice === 0 || this.invoicedMaterialsPrice === 0) {
            return 0
        }
        return 100 * this.invoicedMaterialsPrice / this.quotedMaterialsPrice
    }

    /**
     * Get the total hours of a month (planned + used)
     * @param monthStr
     * @returns {unknown|number}
     */
    getLabourTotalHours(monthStr) {
        let total = 0
        if (!this.labourData[monthStr]) {
            return 0
        }
        const monthData = this.labourData[monthStr]
        if (this.isHistorical(monthStr)) {
            total += Object.values(monthData).reduce((a, b) => a + b.used, 0)
        } else {
            total += Object.values(monthData).reduce((a, b) => a + b.planned, 0)
        }
        return total
    }

    /**
     * Get the total hours predicted. This is historical actuals plus forecasted hours
     * @returns {unknown|number}
     */
    getTotalHoursPredicted() {
        let total = 0
        for (const monthStr in this.labourData) {
            for (const serviceId in this.labourData[monthStr]) {
                if (this.isHistorical(monthStr)) {
                    total += this.labourData[monthStr][serviceId].used
                } else {
                    total += this.labourData[monthStr][serviceId].planned
                }
            }
        }
        return total
    }


    /**
     * Get the cumulative labour hours to date
     * @param monthStr
     * @returns {number}
     */
    getLabourHoursCumulativePercent(monthStr) {
        let total = 0
        for (const monthStr2 in this.labourData) {
            total += this.getLabourTotalHours(monthStr2)
            if (monthStr2 === monthStr) {
                break
            }
        }

        const predicted = this.getTotalHoursPredicted()
        if (predicted === 0) {
            return 0
        }
        else {
            return 100 * total / predicted
        }
    }

    /**
     * Get the minimum labour percentage for a month. This is
     * the percentage from last month or the invoiced percentage
     * if last month is historical
     * @param monthStr
     * @returns {*|number}
     */
    getMinLabourPercentage(monthStr) {
        if (!this.forecast.labour || !this.forecast.labour[monthStr]) {
            return 0
        }

        const lastMonthStr = this.previousMonthStr(monthStr)
        let lastMonthPercentage = 0
        if (this.isHistorical(lastMonthStr)) {
            lastMonthPercentage = this.getInvoicedLabourPercentage()
        } else {
            lastMonthPercentage = this.forecast.labour[lastMonthStr].planned
        }
        return lastMonthPercentage
    }

    /**
     * Get the minimum materials percentage for a month. This is
     * the percentage from last month or the invoiced percentage
     * if last month is historical
     * @param monthStr
     * @returns {*|number}
     */
    getMinMaterialsPercentage(monthStr) {
        if (this.isHistorical(monthStr) || !this.forecast.materials || !this.forecast.materials[monthStr]) {
            return 0
        }

        const lastMonthStr = this.previousMonthStr(monthStr)
        let lastMonthPercentage = 0
        if (this.isHistorical(lastMonthStr)) {
            lastMonthPercentage = this.getInvoicedMaterialsPercentage()
        } else {
            lastMonthPercentage = this.forecast.materials[lastMonthStr].planned
        }
        return lastMonthPercentage
    }


    /**
     * Get the materials cost for a month
     * This is the difference between the planned percentage
     * for the current month and the previous month
     * multiplied by the remaining materials cost
     * @param monthStr
     * @returns {*|number}
     */
    forecastMaterialsCostDollars(monthStr) {
        if (this.isHistorical(monthStr) || !this.forecast.materials || !this.forecast.materials[monthStr]) {
            return 0
        }
        if (!this.forecast.materials) {
            return 0
        }

        const lastMonthStr = this.previousMonthStr(monthStr)
        let lastMonthPercentage = 0
        if (this.isHistorical(lastMonthStr)) {
            lastMonthPercentage = this.getInvoicedMaterialsPercentage()
        } else {
            if (this.forecast.materials[lastMonthStr]) {
                lastMonthPercentage = this.forecast.materials[lastMonthStr].planned
            }
            else {
                lastMonthPercentage = 0
            }
        }

        const currentMonthPercentage = this.forecast.materials[monthStr].planned
        const percentage = currentMonthPercentage - lastMonthPercentage

        return this.quotedMaterialsCost * percentage / 100
    }


    /**
     * Get the materials price for a month
     * This is the difference between the planned percentage
     * for the current month and the previous month
     * multiplied by the remaining materials price
     * @param monthStr
     * @returns {*|number}
     */
    forecastMaterialsPriceDollars(monthStr) {
        if (this.isHistorical(monthStr) || !this.forecast.materials || !this.forecast.materials[monthStr]) {
            return 0
        }
        if (!this.forecast.materials) {
            return 0
        }
        const lastMonthStr = this.previousMonthStr(monthStr)
        let lastMonthPercentage = 0
        if (this.isHistorical(lastMonthStr)) {
            lastMonthPercentage = this.getInvoicedMaterialsPercentage()
        } else {
            if (this.forecast.materials[lastMonthStr]) {
                lastMonthPercentage = this.forecast.materials[lastMonthStr].planned
            }
            else {
                lastMonthPercentage = 0
            }
        }

        const currentMonthPercentage = this.forecast.materials[monthStr].planned
        const percentage = currentMonthPercentage - lastMonthPercentage

        return this.quotedMaterialsPrice * percentage / 100
    }

    /**
     * Get the expected labour income for a month
     * Works off cumulative forecasted labour percentage
     * @param monthStr
     * @returns {number}
     */
    forecastLabourIncomeDollars(monthStr) {
        if (this.isHistorical(monthStr) || !this.forecast.labour || !this.forecast.labour[monthStr]) {
            return 0
        }

        const lastMonthStr = this.previousMonthStr(monthStr)
        let lastMonthPercentage = 0
        if (this.isHistorical(lastMonthStr)) {
            lastMonthPercentage = this.getInvoicedLabourPercentage()
        } else {
            if (this.forecast.labour[lastMonthStr]) {
                lastMonthPercentage = this.forecast.labour[lastMonthStr].planned
            }
            else {
                lastMonthPercentage = 0
            }
        }

        const currentMonthPercentage = this.forecast.labour[monthStr].planned
        const percentage = currentMonthPercentage - lastMonthPercentage
        return this.quotedLabourDollars * percentage / 100
    }

    materialsPriceTotal() {
        let total = this.invoicedMaterialsPrice
        for (const monthStr in this.forecast.materials) {
            total += this.forecastMaterialsPriceDollars(monthStr)
        }
        return total
    }

    materialsCostTotal() {
        let total = this.invoicedMaterialsCost
        for (const monthStr in this.forecast.materials) {
            total += this.forecastMaterialsCostDollars(monthStr)
        }
        return total
    }

    labourTotalDollars() {
        let total = this.invoicedLabourDollars
        for (const monthStr in this.forecast.labour) {
            total += this.forecastLabourIncomeDollars(monthStr)
        }
        return total
    }

    /**
     * Get the total hours of all months (planned)
     * @returns {number}
     */
    getPlannedTotalHours() {
        let total = 0
        for (const monthStr in this.labourData) {
            if (this.isHistorical(monthStr)) {
                continue
            }
            total += this.getMonthPlannedTotalHours(monthStr)
        }
        return total
    }


    /**
     * Get the total hours of a month (planned only)
     * @param monthStr
     * @returns {unknown|number}
     */
    getMonthPlannedTotalHours(monthStr) {
        if (!this.labourData[monthStr]) {
            return 0
        }
        const monthData = this.labourData[monthStr]
        return Object.values(monthData).reduce((a, b) => a + b.planned, 0)
    }

    autoLabourForecast() {
        const totalPlannedHours = this.getPlannedTotalHours()
        const unInvoicedPercentage = (100 - this.getInvoicedLabourPercentage())/100
        for (const monthStr in this.labourData) {
            const predictedHours = this.getCumulativePredictedHours(monthStr)
            let ratio = (predictedHours / totalPlannedHours )* unInvoicedPercentage
            if (isNaN(ratio)) {
                ratio = 0
            }
            this.forecast.labour[monthStr].planned = this.getInvoicedLabourPercentage() + (ratio * 100)
        }
    }

    /**
     * Get the cumulative default billing labour percentage for a month
     * As a percentage of the forecasted total hours
     * @param monthStr
     * @returns {number|number}
     */
    getCumulativePredictedHours(monthStr) {
        let total = 0
        for (const monthStr2 in this.labourData) {
            if (this.isHistorical(monthStr2)) {
                continue
            }
            total += this.getLabourTotalHours(monthStr2)
            if (monthStr2 === monthStr) {
                break
            }
        }

        return total
    }

    forecastTotalIncomeDollars(monthStr) {
        return this.forecastLabourIncomeDollars(monthStr) + this.forecastMaterialsPriceDollars(monthStr)
    }

    forecastTotalCostDollars(monthStr) {
        return this.forecastMaterialsCostDollars(monthStr)
    }
}
