<script>
import {defineComponent} from "vue"
import {getStaffList} from "@/lib/lookup"
import {getWeekNumber, toLocalString, formatDate} from "@/lib/dateTimeUtilities"
import {leadProbabilities, leadStages} from "@/lib/constants"
import CreateProspect from "@/components/clients/CreateProspect.vue"
import {isNotEmpty, isNotNull} from "@/lib/validationRules"
import ContactEditor from "@/components/contacts/ContactEditor.vue"
import ClientPopup from "@/components/clients/ClientPopup.vue"

export default defineComponent({
  name: "LeadForm",
  permissions: ["Read Leads", "Modify Leads", "Delete Leads", "Modify Job Task", ...CreateProspect.permissions, ...ContactEditor.permissions, ...ClientPopup.permissions],
  components: {ContactEditor, CreateProspect, ClientPopup},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ["input"],
  computed: {
    allStaff: function () {
      return this.staff.map(staff => staff.value)
    },
    clientName: function () {
      const option = this.clientOptions.find(client => client.value === this.lead.client_uuid)
      if (option) return option.text
      else return ""
    },
    quoteOptions: function () {
      const quoteOptions = []
      for (const quote of this.quotes) {
        if (quote.Client.UUID !== this.lead.client_uuid) continue

        const sortKey = quote.ID.replace(/\D/g, '') || 0
        quoteOptions.push({text: `[${quote.ID}] ${quote.Name}`, value: quote.UUID, sortKey: parseInt(sortKey)})
      }

      quoteOptions.sort((a, b) => {
        return b.sortKey - a.sortKey
      })
      return quoteOptions
    },
    clientOptions: function () {
      const clientOptions = []
      for (const client of this.clients) {
        clientOptions.push({text: client.Name, value: client.UUID})
      }
      return clientOptions
    },
    contactOptions: function () {
      if (!this.lead.client_uuid) return []
      const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
      if (!client) return []

      const contactOptions = []
      if (!client.Contacts) return []
      const contacts = this.$ensureArray(client.Contacts.Contact)
      for (const contact of contacts) {
        contactOptions.push({text: contact.Name, value: contact.UUID})
      }
      return contactOptions
    },
    selectedContact: function () {
      if (this.lead.contact_uuid) {
        const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
        if (!client) return []
        if (!client.Contacts) return []
        const contacts = this.$ensureArray(client.Contacts.Contact)
        return contacts.find(contact => contact.UUID === this.lead.contact_uuid)
      }
      return null
    },
    selectedClient: function () {
      if (this.lead.client_uuid) {
        return this.clients.find(client => client.UUID === this.lead.client_uuid)
      }
      return null
    }
  },
  methods: {
    isNotEmpty,
    isNotNull,
    isNotEmptyExceptLost(value) { // can be blank if stage is lost

      if (this.lead.stage === "lost") return true
      else return !!value
    },
    probabilitySetCorrectly() {
      return (this.lead.budget !== null || this.lead.quote_uuid !== null) && this.lead.probability !== null
    },
    loadSettings() {
      this.$BwfApi.get("admin/settings").then(response => {
        this.defaultLeadsTimeAllocation = response.default_leads_time_allocation
      })
    },
    loadStaff() {
      getStaffList(this, (res) => {
        this.staff = []
        for (const staff of res) {
          this.staff.push({text: staff.name, value: staff.wfm_userid})
        }
      })
    },
    loadClients(clearCache = false) {
      return this.$WfmApi.get("client.api/list", {voidCache: clearCache}).then(response => {
        if (response.Clients) {
          this.clients = this.$ensureArray(response.Clients.Client)
        }
      })
    },
    loadQuotes() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      this.startDate = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      this.endDate = toLocalString(endDate).substr(0, 10).replace(/-/g, "")

      const query = {"from": this.startDate, "to": this.endDate, "detailed": "false", "voidCache": true}
      this.$WfmApi.get("quote.api/list", query).then(response => {
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
        }
      })
    },
    loadSalesJob() {
      if (this.lead.sales_job_id) {
        this.$WfmApi.get(`job.api/get/${this.lead.sales_job_id}`, {voidCache: false}).then((response) => {
          if (response.Job) {
            this.salesJob = response.Job
          }
        })
      }
    },
    addSalesNote() {
      if (this.newSalesNotes) {
        const today = new Date()
        const date = today.toISOString().substr(0, 10)
        const sep = '----------'
        const staffName = this.staff.find(staff => staff.value === this.$access.wfm_uuid).text
        this.lead.sales_notes = `${date} - ${staffName}\n${this.newSalesNotes}\n${sep}\n\n${this.lead.sales_notes}`
        this.newSalesNotes = ""
      }
    },
    prospectCreated(prospect) {
      this.showCreateClientPopup = false
      this.loadClients(true).then(() => {
        this.$set(this.lead, "client_uuid", prospect.UUID)
        this.$set(this.lead, "contact_uuid", prospect.Contacts.Contact.UUID)
      })
    },
    saveContact() {
      const contact = this.$refs.contactEditor.getContact()

      const data = {
        Contact: {
          Client: {UUID: this.lead.client_uuid},
          Name: contact.name,
          Mobile: contact.mobile,
          Email: contact.email,
          Phone: contact.phone,
          Position: contact.position,
        }
      }

      this.$WfmApi.post("client.api/contact", data).then((response) => {
        this.loadClients(true)
        this.showCreateContactPopup = false
        this.$set(this.lead, "contact_uuid", response.Contact.UUID)
      })
    },
    massageSteps() {
      const steps = JSON.parse(JSON.stringify(this.lead.steps)) // copy
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].complete === 0) {
          steps[i].checked = false
          steps[i].notApplicable = false
        } else if (steps[i].complete === 1) {
          steps[i].checked = true
          steps[i].notApplicable = false
        } else {
          steps[i].checked = false
          steps[i].notApplicable = true
        }
      }
      this.steps = steps
    },
    toggle(idx, step) {
      step.notApplicable = !step.notApplicable
      this.$set(this.steps, idx, step)
    },
    advanceStage() {
      const stages = this.leadStages.map(stage => stage.value)
      const idx = stages.indexOf(this.lead.stage)
      if (idx < stages.length - 1) {
        this.lead.stage = stages[idx + 1]
      }
    },
    async checkFollowupTask() {
      // See if there is an existing follow-up task for the lead
      // If there is not, create one.
      // If there is, load it and see if it is done
      // If it is done then create a new task
      // If it is not done then update it
      if (this.lead.follow_up_task) {
        const task = await this.$BwfApi.get("staff-plan/task/" + this.lead.follow_up_task)
        if (task) {
          if (task.done) {
            return this.createFollowupTask()
          } else {
            // update and save task
            const follow_up_date = new Date(this.lead.follow_up_date)
            const {year, week} = getWeekNumber(follow_up_date)
            const period = year.toString() + "-" + week.toString().padStart(2, "0")
            task.year = year
            task.week = week
            task.period = period
            task.allocated_hours = this.defaultLeadsTimeAllocation
            const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
            task.notes = `Lead Follow up: ${client.Name} - ${this.lead.title}\n ${this.lead.follow_up_action}`
            return this.createFollowupTask(task)
          }
        } else {
          return this.createFollowupTask()
        }
      } else {
        return this.createFollowupTask()
      }
    },
    createFollowupTask(existingTask) {
      const follow_up_date = new Date(this.lead.follow_up_date)
      const {year, week} = getWeekNumber(follow_up_date)
      const period = year.toString() + "-" + week.toString().padStart(2, "0")
      const client = this.clients.find(client => client.UUID === this.lead.client_uuid)
      const task = existingTask || {
        job_id: -1,
        staff_plan_id: null,
        service_id: -1,
        staff_uuid: this.lead.owner_uuid,
        year: year,
        week: week,
        period: period,
        priority: 2,
        done: false,
        allocated_hours: this.defaultLeadsTimeAllocation,
        task_order: 0,
        notes: `Lead Follow up: ${client.Name} - ${this.lead.title}\n ${this.lead.follow_up_action}`,
      }
      return this.$BwfApi.post("staff-plan/task", task)
    },
    async checkSalesJob() {
      const salesJob = (this.lead.sales_job_id)
      if (salesJob) {
        return this.updateSalesJob()
      } else {
        return this.createSalesJob()
      }
    },
    updateSalesJob() {
      return this.$WfmApi.get(`job.api/get/${this.lead.sales_job_id}`, {voidCache: true}).then((response) => {

        if (response.Job) {
          const job = response.Job
          const jobName = `SALES: ${this.lead.title}`
          const startDate = job.StartDate.substr(0, 10).replace(/-/g, "")
          let dueDate = this.lead.follow_up_date.replace(/-/g, "")

          // check due date is later than start date if not fix it
          if (parseInt(dueDate) < parseInt(startDate)) {
            dueDate = startDate
          }

          if (job.Name !== jobName) {
            const data = {
              Job: {
                ID: job.ID,
                Name: jobName,
                StartDate: startDate,
                DueDate: dueDate,
                Description: `Job for Tracking time against: ${this.lead.title}`,
              }
            }
            this.$WfmApi.put("job.api/update", data)
          }

          let currently_assigned = []
          if (job.Assigned && job.Assigned.Staff) {
            const staffList = this.$ensureArray(job.Assigned.Staff)
            currently_assigned = staffList.map(staff => staff.UUID)
          }

          this.updateJobState()
          this.updateSalesJobStaff("", this.settings.partnerUUID, "", this.lead.owner_uuid, currently_assigned, this.lead.assigned_staff)
          this.updateCustomFields()
        } else {
          return this.createSalesJob()
        }
      })
    },
    createSalesJob() {
      const jobName = `SALES: ${this.lead.title}`
      let dueDate = this.lead.follow_up_date.replace(/-/g, "")
      const startDate = new Date().toISOString().substr(0, 10).replace(/-/g, "")
      // check due date is later than start date if not fix it
      if (parseInt(dueDate) < parseInt(startDate)) {
        dueDate = startDate
      }
      const data = {
        Job: {
          Name: jobName,
          StartDate: startDate,
          DueDate: dueDate,
          CategoryUUID: this.settings.salesJobCategory,
          ClientUUID: this.lead.client_uuid,
          ContactUUID: this.lead.contact_uuid,
          TemplateUUID: this.settings.salesJobTemplate,
          Description: `Job for Tracking time against: ${this.lead.title}`,
        }
      }
      return this.$WfmApi.post("job.api/add", data).then((response) => {
        this.lead.sales_job_id = response.Job.ID
        this.updateJobState()
        this.updateSalesJobStaff("", this.settings.partnerUUID, "", this.lead.owner_uuid, [], this.lead.assigned_staff)
        this.updateCustomFields()
        this.createJobTask(response.Job.Tasks.Task.UUID)
      })
    },
    createJobTask(existingTaskId) {
      // A task is auto-created when a job is created, just edit it
      let dueDate = this.lead.follow_up_date.replace(/-/g, "")
      const startDate = new Date().toISOString().substr(0, 10).replace(/-/g, "")

      const data = {
        Task: {
          Description: "",
          DueDate: dueDate,
          EstimatedMinutes: 60,
          Label: "",
          StartDate: startDate,
          TaskUUID: this.settings.salesJobTaskUUID,
          UUID: existingTaskId,
        }
      }
      this.$WfmApi.put("job.api/task", data)
    },
    updateCustomFields() {
      const customFields = {
        CustomFields: {
          CustomField: [
            {
              "UUID": this.settings.excludeFromKpiCustomFieldUUID,
              'Boolean': true
            },
            {
              "UUID": this.settings.clientTypeCustomFieldUUID,
              'Text': this.lead.is_prospect ? "Prospect" : "Existing"
            }
          ]
        }
      }
      return this.$WfmApi.put("job.api/update/" + this.lead.sales_job_id + "/customfield", customFields)
    },
    updateSalesJobStaff(oldPartnerUUID, newPartnerUUID, oldManagerUUID, newManagerUUID, oldStaffUUIDs, newStaffUUIDs) {
      const promises = []
      // if (oldPartnerUUID !== newPartnerUUID) {
      //   const data = {
      //     'Job': {
      //       'ID': this.lead.sales_job_id,
      //     }
      //   }
      //   data['Job']['add-partner'] = {'@uuid': newPartnerUUID}
      //   if (oldPartnerUUID) {
      //     data['Job']['remove-partner'] = {'@uuid': oldPartnerUUID}
      //   }
      //   console.log(data)
      //   promises.push(this.$WfmApi.put("job.api/assign", data))
      // }

      // if (oldManagerUUID !== newManagerUUID) {
      //   const data = {
      //     'Job': {
      //       'ID': this.lead.sales_job_id,
      //     }
      //   }
      //   data['Job']['add-manager'] = {'@uuid': newManagerUUID}
      //   if (oldManagerUUID) {
      //     data['Job']['remove-manager'] = {'@uuid': oldManagerUUID}
      //   }
      //   console.log(data)
      //   promises.push(this.$WfmApi.put("job.api/assign", data))
      // }
      if (!newStaffUUIDs) {
        newStaffUUIDs = []
      }

      if (!this.$areArraysEqual(oldStaffUUIDs, newStaffUUIDs)) {
        const toAdd = newStaffUUIDs.filter(x => !oldStaffUUIDs.includes(x))
        const toRemove = oldStaffUUIDs.filter(x => !newStaffUUIDs.includes(x))

        for (const uuid of toAdd) {
          const data = {
            'Job': {
              'ID': this.lead.sales_job_id,
              'add': {'@uuid': uuid}
            }
          }
          promises.push(this.$WfmApi.put("job.api/assign", data))
        }

        for (const uuid of toRemove) {
          const data = {
            'Job': {
              'ID': this.lead.sales_job_id,
              'remove': {'@uuid': uuid}
            }
          }
          promises.push(this.$WfmApi.put("job.api/assign", data))
        }
      }
      return Promise.all(promises)
    },
    updateJobState() {
      let jobStatus = `Sales-${this.$capitalize(this.lead.stage)}`
      if (['won', 'lost', 'archive'].includes(this.lead.stage)) {
        jobStatus = 'Completed'
      }
      const data = {
        Job: {
          ID: this.lead.sales_job_id,
          State: jobStatus
        }
      }
      return this.$WfmApi.put("job.api/state", data)
    },
    save() {
      this.saving = true
      if (!this.$refs["my-form"].validate()) {
        this.$notify.show({type: "error", text: "There are incomplete fields"})
        this.saving = false
        return
      }
      this.checkSalesJob().then(() => {
        const copy = {...this.lead}
        // close lead if it's won or lost, but only ever do it once
        if ((copy.stage === "won" || copy.stage === "lost") && !copy.closed) {
          copy.closed = formatDate(new Date(), "-")
        }

        copy.steps = []
        for (let i = 0; i < this.steps.length; i++) {
          copy.steps[i] = {name: this.steps[i].name}
          if (this.steps[i].notApplicable) {
            copy.steps[i].complete = -1
          } else if (this.steps[i].checked) {
            copy.steps[i].complete = 1
          } else {
            copy.steps[i].complete = 0
          }
        }

        this.checkFollowupTask().then((followUpTask) => {
          copy.follow_up_task = followUpTask.staff_plan_id
          copy.assigned_staff = copy.assigned_staff ? copy.assigned_staff.join(",") : "" // serialize assigned staff
          this.$BwfApi.post("leads", copy).then(response => {
            this.$notify.toast("Saved")
            this.$emit("input", response.Lead)

            this.$notify.toast("Saved")
            this.saving = false
          })
        })
      })
    },
    deleteLead() {
      this.$BwfApi.delete("leads/" + this.lead.lead_id).then(() => {
        this.$emit("input")
        this.$notify.toast("Deleted")
        this.showDeletePopup = false
      })
    }
  },
  data() {
    return {
      quotes: [],
      clients: [],
      lead: {},
      steps: [],
      followUpDatePicker: false,
      expiryDatePicker: false,
      leadStages: leadStages,
      acceptanceProbabilities: leadProbabilities,
      showCreateContactPopup: false,
      showCreateClientPopup: false,
      showLeadContactPopup: false,
      showDeletePopup: false,
      staff: [],
      defaultLeadsTimeAllocation: 1,
      salesJob: {},
      newSalesNotes: "",
      showSalesNotesEditor: false,
      saving: false,
      settings: {
        salesJobCategory: "9c483d68-9687-4b90-aca3-df75ef6bce26",
        salesJobTemplate: "9e3a8287-099a-4a66-a638-fd57f1d80dc0",
        clientTypeCustomFieldUUID: "9e3a86b0-6394-4a3c-add4-b34e58fbb262",
        excludeFromKpiCustomFieldUUID: "9c483d6b-44ba-42a0-85ac-24c3a5d6bcab",
        partnerUUID: '9c483d76-7043-4010-b5a6-cc01ca9712b7',
        salesJobTaskUUID: '9c483d78-0534-4f80-9305-055549bc0f9a',
      }
    }
  },
  mounted() {
    this.lead = {...this.value}
    if (this.lead.assigned_staff && typeof this.lead.assigned_staff === "string") {
      this.lead.assigned_staff = this.lead.assigned_staff.split(",") // unserialize assigned staff
    }

    if (!this.lead.owner_uuid) this.lead.owner_uuid = this.$access.wfm_uuid // default to current user
    this.loadSettings()
    this.loadStaff()
    this.loadQuotes()
    this.loadClients()
    this.massageSteps()
    this.loadSalesJob()
  }
})
</script>

<template>
  <v-container>
    <v-form ref="my-form">
      <v-row>
        <v-col>
          <h6>Lead Information</h6>
          <v-text-field label="Title" v-model="lead.title" dense :rules="[isNotEmpty]"></v-text-field>

          <v-textarea label="Description" v-model="lead.description" dense :rules="[isNotEmpty]"></v-textarea>

          <v-autocomplete label="Lead Owner" v-model="lead.owner_uuid" dense :items="staff"
                          :rules="[isNotEmpty]"></v-autocomplete>

          <v-select label="Assigned Staff" multiple v-model="lead.assigned_staff" :items="staff" dense>
            <template v-slot:append-item>
              <v-list-item ripple @mousedown.prevent>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn flat v-if="lead.assigned_staff.length === allStaff.length" @click="lead.assigned_staff =[]"
                           plain> Select None
                    </v-btn>
                    <v-btn flat v-else @click="lead.assigned_staff = allStaff" plain>Select All</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

          </v-select>

          <v-autocomplete label="Client" v-model="lead.client_uuid" dense :items="clientOptions"
                          class="selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" class="display-inline ml-3 pt-1" fab small @click="showCreateClientPopup=true"
                     v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create new client</span>
          </v-tooltip>

          <v-autocomplete label="Contact" v-model="lead.contact_uuid" dense :items="contactOptions"
                          class="contact-selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
          <v-btn text x-small dense @click="showLeadContactPopup=true" v-if="lead.contact_uuid">
            <v-icon>mdi-window-restore</v-icon>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" class="display-inline ml-3 pt-1" fab small
                     @click="showCreateContactPopup=true" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create new contact</span>
          </v-tooltip>

          <ClientPopup v-model="showLeadContactPopup" v-if="selectedClient && selectedContact"
                       :selectedClient="selectedClient" :selectedContact="selectedContact"></ClientPopup>

          <div>
            <v-radio-group v-model="lead.is_prospect" :rules="[isNotNull]">
              <v-radio label="Prospect" :value="1"></v-radio>
              <v-radio label="Existing Client" :value="0"></v-radio>
            </v-radio-group>
          </div>

          <v-autocomplete label="Probability of acceptance" v-model="lead.probability"
                          v-if="lead.budget || lead.quote_uuid"
                          :rules="[probabilitySetCorrectly]"
                          :items="acceptanceProbabilities" dense></v-autocomplete>

          <v-menu v-model="expiryDatePicker" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="lead.expiry" label="Lead Expiry" readonly v-bind="attrs" v-on="on"
                            :rules="[isNotEmpty]"></v-text-field>
            </template>
            <v-date-picker v-model="lead.expiry" @input="expiryDatePicker = false"></v-date-picker>
          </v-menu>

          <div>
            <v-autocomplete label="Lead Stage" v-model="lead.stage" dense :items="leadStages"
                            class="selector display-inline" :rules="[isNotEmpty]"></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" class="display-inline ml-3 pt-1" fab small @click="advanceStage" v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>Advance Stage</span>
            </v-tooltip>
          </div>

          <div v-if="lead.stage === 'scope'">
            <v-btn color="primary" class="pt-2"
                   href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/quotes/create"
                   target="_blank">
              <v-icon>mdi-plus</v-icon>
              <span>Create Quote</span>
            </v-btn>
          </div>

          <div v-if="['bid', 'won', 'lost'].includes(lead.stage)">
            <v-autocomplete label="Quote" v-model="lead.quote_uuid" dense class="quote-selector display-inline"
                            :items="quoteOptions"></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" class="display-inline ml-5" fab x-small @click="loadQuotes" v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Reload Quotes</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="display-inline ml-3 pt-2" fab x-small
                       href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/quotes/create"
                       target="_blank" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create Quote</span>
            </v-tooltip>
          </div>
          <div v-if="!lead.quote_uuid">
            <v-text-field label="Estimated Budget" v-model="lead.budget" dense prefix="$"></v-text-field>
          </div>

        </v-col>
        <v-col>
          <v-btn v-if="salesJob.WebURL" color="primary" :href="salesJob.WebURL" class="full-width" target="_blank">View
            on WorkflowMax
          </v-btn>

          <h6>Steps</h6>
          <div v-for="(step, idx) in steps" v-bind:key="idx">
            <v-checkbox v-model="step.checked" dense class="display-inline">
              <template v-slot:label>
                <span class="strikethrough" v-if="step.notApplicable">{{ step.name }}</span>
                <span v-else>{{ step.name }}</span>
              </template>
            </v-checkbox>
            <v-btn x-small fab text class="display-inline" @click="toggle(idx, step)">
              N/A
            </v-btn>

          </div>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <h6>Sales Notes</h6>
              <v-textarea label="New Sales Note" dense persistent-hint v-model="newSalesNotes"
                          rows="2" auto-grow class="text-smaller"></v-textarea>
              <v-btn color="primary" @click="addSalesNote" class="full-width">
                Add Sales Note
              </v-btn>
              <div class="sales-notes mt-5">

                <v-btn fab absolute top right x-small @click="showSalesNotesEditor=true" color="primary"
                       v-if="showSalesNotesEditor===false">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn fab absolute top right x-small @click="showSalesNotesEditor=false" color="primary" v-else>
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <div class="sales-notes-inner">
                  <v-textarea dense persistent-hint v-model="lead.sales_notes" v-if="showSalesNotesEditor"
                              class="text-smaller" hide-details auto-grow></v-textarea>

                  <div v-else>{{ lead.sales_notes }}</div>
                </div>

              </div>
            </v-card-text>
          </v-card>

          <v-card class="mt-4">
            <v-card-text>
              <h6>Follow Up</h6>
              <v-textarea label="Follow up action" dense v-model="lead.follow_up_action" class="mt-8 text-smaller"
                          :rules="[isNotEmpty]"
                          hint="What action should be taken at the follow up date" persistent-hint></v-textarea>

              <v-menu v-model="followUpDatePicker" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="lead.follow_up_date" label="Follow Up Date" readonly v-bind="attrs" v-on="on"
                                :rules="[isNotEmpty]"></v-text-field>
                </template>
                <v-date-picker v-model="lead.follow_up_date" @input="followUpDatePicker = false"></v-date-picker>
              </v-menu>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn color="red" @click="showDeletePopup=true" dark>Delete
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save" :disabled="saving">Save
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <v-dialog v-model="showCreateClientPopup">
      <v-card>
        <v-card-title>
          <span class="headline">Create Client</span>
        </v-card-title>
        <v-card-text>
          <CreateProspect @saved="prospectCreated"></CreateProspect>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCreateContactPopup">
      <v-card>
        <v-card-title>
          <span class="headline">Create Contact</span>
        </v-card-title>
        <v-card-text>
          <ContactEditor :clientName="clientName" hide-custom-fields ref="contactEditor"></ContactEditor>
          <v-btn color="primary" @click="saveContact">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeletePopup" :width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Delete</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this lead?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="deleteLead">Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h6 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-selector {
  width: 272px;
}

.selector {
  width: 315px;
}

.quote-selector {
  width: 270px;
}

.display-inline {
  display: inline-block;
}

.strikethrough {
  text-decoration: line-through;
}

.inline-block {
  display: inline-block;
}

.sales-notes {
  white-space: pre-wrap;
  border: 1px solid #e0e0e0;
  padding: 10px;
  font-size: 0.8rem;
  position: relative;
  line-height: normal;

}

.sales-notes-inner {
  max-height: 250px;
  overflow: auto;
}

.text-smaller {
  font-size: 0.8rem;
}

.text-smaller >>> textarea {
  line-height: normal;
}

.full-width {
  width: 100%;
  padding: 0;
}
</style>