<template>
  <v-container fluid>
    <h1>Charge Rates
    <ContextHelp help-text="This page shows the charge rates for all services, broken down by service and task type.
    Calculation:
* All jobs are loaded which started in the last 5 years and completed since the report ran last (monthly)
* For each task in each job:
  - Go through all invoices which are paid or approved and find the service type by task name. Get the total amount invoiced
  - Go through each time entry and how much time was spent on each task and also break this down by service and staff member.
  - Calculate the job hourly rate by dividing the total amount invoiced for the job by the total number of hours spent on the job, do the same for each task, each service, each staff member

NOTE: Bug: The summary graph does not summarise the data correctly
NOTE: Only jobs since last report run are included in the calculations
NOTE: Task names are used to match between invoices and jobs
"></ContextHelp>
    </h1>
    <ReportChargeRatesGraph></ReportChargeRatesGraph>
  </v-container>
</template>

<script>
import ReportChargeRatesGraph from "@/components/reports/ReportChargeRatesGraph.vue"
import ContextHelp from "@/components/toolkit/ContextHelp.vue";

export default {
  name: "ReportChargeRatesPage",
  permissions: [...ReportChargeRatesGraph.permissions],
  components: {ContextHelp, ReportChargeRatesGraph},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>