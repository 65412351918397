<template>
  <v-container fluid>
    <h1>Service Schedule Summary
      <ContextHelp help-text="This report shows the summary of the number of hours scheduled for each staff member for a given month.
    As scheduling happens on a per-week basis, where a week crosses a month boundary, the week apportioned on a pro-rata basis."/>
    </h1>
    <ReportServiceScheduleSummary></ReportServiceScheduleSummary>
  </v-container>
</template>

<script>


import ReportServiceScheduleSummary from "@/components/capacity/ReportServiceScheduleSummary.vue"
import ContextHelp from "@/components/toolkit/ContextHelp.vue";

export default {
  name: "ReportServiceScheduleSummaryPage",
  permissions: [],
  components: {ContextHelp, ReportServiceScheduleSummary},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>