import ProjectGrouping from "@/pages/ProjectGrouping"
import CostImporterAdministrator from "@/pages/CostImporterAdministrator.vue"
import CostImporterTransactionMatchingStaff from "@/pages/CostImporterTransactionMatchingStaff.vue"
import AdminRoles from "@/pages/AdminRoles"
import AdminRolePermissions from "@/pages/AdminRolePermissions"
import AdminUsers from "@/pages/AdminUsers"
import AccessDenied from "@/pages/AccessDenied"
import Login from "@/pages/Login"
import JobsReview from "@/pages/JobsReview"
import JobsAuditReport from "@/pages/JobsAuditReport"
import Invoicing from "@/pages/Invoicing"
import CostMover from "@/pages/CostMover"
import ContactList from "@/pages/ContactList"
import LeadsPage from "@/pages/LeadsPage.vue"
import AdminSettings from "@/pages/AdminSettings"
import TestPage from "@/pages/TestPage"
import AdminServicesPage from "@/pages/AdminServicesPage.vue"
import AdminCapacityPage from "@/pages/AdminCapacityPage.vue"
import ReportCapacityWeeklyByServicePage from "@/pages/ReportCapacityWeeklyByServicePage.vue"
import ReportWorkloadPage from "@/pages/ReportWorkloadPage.vue"
import StaffPlanSchedulePage from "@/pages/StaffPlanSchedulePage.vue"
import JobCapacityPage from "@/pages/JobCapacityPage.vue"
import ReportLongTermCapacityPage from "@/pages/ReportLongTermCapacityPage.vue"
import StaffPlanScheduleReviewPage from "@/pages/StaffPlanScheduleReviewPage.vue"
import WIPSetupPage from "@/pages/WIPSetupPage.vue"
import ReportKPIPage from "@/pages/ReportKPIPage.vue"
import ReportServiceScheduleSummaryPage from "@/pages/ReportServiceScheduleSummaryPage.vue"
import ReportPipelinePage from "@/pages/ReportPipelinePage.vue"
import AdminXeroContactSupplierMatchingPage from "@/pages/AdminXeroContactSupplierMatchingPage.vue"
import AdminBackgroundTasks from "@/pages/AdminBackgroundTasks.vue"
import ReportProjectCostVariancePage from "@/pages/ReportProjectCostVariancePage.vue"
import JobsReviewProjectManagers from "@/pages/JobsReviewProjectManagers.vue"
import ReportLeadsPage from "@/pages/ReportLeadsPage.vue"
import ReportChargeRatesPage from "@/pages/ReportChargeRatesPage.vue"
import JobTimePlannerPage from "@/pages/JobTimePlannerPage.vue"
import ReportRawData from "@/pages/ReportRawData.vue"

export const menuGroups = ["Staff", "Project Management", "Sales", "Business Management", "Reports", "Settings", "Other"]
export const routes = [
  {
    name: "AccessDeniedPage",
    title: "Access Denied",
    path: "/403",
    showInMenu: false,
    menuGroup: "Internal",
    component: AccessDenied,
  },
  {
    name: "LoginPage",
    title: "Login",
    path: "/login",
    showInMenu: false,
    menuGroup: "Internal",
    component: Login,
  },
  {
    name: "Home",
    title: "Home",
    path: "/",
    showInMenu: false,
    icon: "mdi-home",
    menuGroup: "Internal",
    component: JobsReview,
  },
  {
    name: "JobsReview",
    title: "Jobs Review (Staff)",
    path: "/jobs/review",
    showInMenu: true,
    menuGroup: "Project Management",
    icon: "mdi-account-hard-hat",
    component: JobsReview,
    keywords: "",
  },
    {
    name: "JobsReviewProjectManagers",
    title: "Jobs Review (Project Managers)",
    path: "/jobs/review-pm",
    showInMenu: true,
    menuGroup: "Project Management",
    icon: "mdi-account-hard-hat-outline",
    component: JobsReviewProjectManagers,
  },
  {
    name: "JobsAuditReport",
    title: "Jobs Audit Report",
    path: "/report/jobs-audit",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-table",
    component: JobsAuditReport,
  },
  {
    name: "ContactList",
    title: "Contact List",
    path: "/clients/contact-list",
    showInMenu: true,
    menuGroup: "Sales",
    icon: "mdi-account-circle",
    component: ContactList,
  },
  {
    name: "LeadsPage",
    title: "Leads",
    path: "/leads",
    showInMenu: true,
    menuGroup: "Sales",
    icon: "mdi-filter-variant",
    component: LeadsPage,
  },
  {
    name: "Invoicing",
    title: "Invoicing",
    path: "/jobs/invoicing",
    showInMenu: true,
    menuGroup: "Business Management",
    icon: "mdi-currency-usd",
    component: Invoicing,
  },
  {
    name: "CostMover",
    title: "Cost Mover",
    path: "/costs/move",
    showInMenu: true,
    menuGroup: "Project Management",
    icon: "mdi-currency-usd",
    component: CostMover,
  },
  {
    name: "CostsImportStaff",
    title: "Match Transactions",
    path: "/costs/import-staff",
    showInMenu: true,
    menuGroup: "Staff",
    icon: "mdi-compare-horizontal",
    component: CostImporterTransactionMatchingStaff,
  },
  {
    name: "CostsImportBackoffice",
    title: "Bank Reconciliation",
    path: "/costs/import-backoffice",
    showInMenu: true,
    menuGroup: "Business Management",
    icon: "mdi-cash",
    component: CostImporterAdministrator,
  },
  {
    name: "JobAnalysis",
    title: "Job Analysis",
    path: "/job-analysis",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-google-analytics",
    component: ProjectGrouping,
  },
  {
    name: "JobCapacity",
    title: "Job Forecast",
    path: "/job-capacity",
    showInMenu: true,
    menuGroup: "Project Management",
    icon: "mdi-human-capacity-decrease",
    component: JobCapacityPage,
  },
  {
    name: "ReportWeeklyCapacityByService",
    title: "Weekly Capacity by Service",
    path: "/report/weekly-capacity-service",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-chart-pie-outline",
    component: ReportCapacityWeeklyByServicePage,
  },
  {
    name: "ReportLongTermCapacity",
    title: "Capacity Report",
    path: "/report/long-term-capacity",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-chart-bar",
    component: ReportLongTermCapacityPage,
  },
  {
    name: "ReportWorkload",
    title: "Workload",
    path: "/report/workload",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-account-group",
    component: ReportWorkloadPage,
  },
  {
    name: "ReportKPI",
    title: "KPI: Project Focus",
    path: "/report/kpi",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-account-group",
    component: ReportKPIPage,
  },
  {
    name: "ReportPipeline",
    title: "Pipeline",
    path: "/report/pipeline",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-pipe",
    component: ReportPipelinePage,
  },
  {
    name: "ReportServiceScheduleSummaryPage",
    title: "Service Schedule",
    path: "/report/service-schedule",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-room-service",
    component: ReportServiceScheduleSummaryPage,
  },
  {
    name: "ReportProjectCostVariance",
    title: "Project Cost Variance",
    path: "/report/project-cost-variance",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-variable",
    component: ReportProjectCostVariancePage,
  },
    {
    name: "ReportLeads",
    title: "Leads Dashboard",
    path: "/report/leads",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-filter-variant",
    component: ReportLeadsPage,
  },
  {
    name: "ReportChargeRages",
    title: "Charge Rates Report",
    path: "/report/charge-rates",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-cash-100",
    component: ReportChargeRatesPage,
  },

  {
    name: "ReportRawData",
    title: "Raw Report Data",
    path: "/report/raw-data",
    showInMenu: true,
    menuGroup: "Reports",
    icon: "mdi-raw",
    component: ReportRawData,
  },


  {
    name: "StaffPlan",
    title: "Scheduling",
    path: "/staff/scheduling",
    showInMenu: true,
    menuGroup: "Staff",
    icon: "mdi-clipboard-list",
    component: StaffPlanSchedulePage,
    keywords: "workload planning tasks"
  },
  {
    name: "StaffPlanReview",
    title: "Scheduling Review",
    path: "/planning/scheduling-plan",
    showInMenu: true,
    menuGroup: "Project Management",
    icon: "mdi-clipboard-list",
    component: StaffPlanScheduleReviewPage,
  },
  {
    name: "AdminRoles",
    title: "Roles & Permissions",
    path: "/admin/roles",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-account-cog",
    component: AdminRoles,
  },
  {
    name: "AdminRolePermissions",
    title: "Permissions",
    path: "/admin/role/:id/permissions",
    showInMenu: false,
    menuGroup: "Settings",
    icon: "mdi-account-cog",
    component: AdminRolePermissions,
  },
  {
    name: "AdminUsers",
    title: "Users",
    path: "/admin/users",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-account-cog",
    component: AdminUsers,
  },
  {
    name: "AdminSettings",
    title: "Settings",
    path: "/admin/settings",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-account-cog",
    component: AdminSettings,
  },
  {
    name: "AdminServices",
    title: "Services Setup",
    path: "/admin/services",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-toolbox-outline",
    component: AdminServicesPage,
  },
  {
    name: "AdminCapacity",
    title: "Capacity Setup",
    path: "/admin/capacity",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-human-capacity-decrease",
    component: AdminCapacityPage,
  },
  {
    name: "AdminBackgroundTasks",
    title: "Background Tasks",
    path: "/admin/background-tasks",
    showInMenu: true,
    menuGroup: "Settings",
    icon: "mdi-checkbox-marked-circle-auto-outline",
    component: AdminBackgroundTasks,
  },
  {
    name: "WIPSetup",
    title: "WIP Setup",
    path: "/wip-setup",
    showInMenu: true,
    menuGroup: "Business Management",
    icon: "mdi-progress-clock",
    component: WIPSetupPage,
  },
  {
    name: "AdminXeroContactSupplierMatching",
    title: "Xero Contact to Supplier Matching",
    path: "/supplier-matching",
    showInMenu: true,
    menuGroup: "Business Management",
    icon: "mdi-compare-horizontal",
    component: AdminXeroContactSupplierMatchingPage,
  },
  {
    name: "Test",
    title: "Test",
    path: "/test",
    showInMenu: false,
    menuGroup: "",
    icon: "",
    component: TestPage,
  },
    {
    name: "Gantt",
    title: "Test",
    path: "/gantt",
    showInMenu: false,
    menuGroup: "",
    icon: "",
    component: JobTimePlannerPage,
  },
]
