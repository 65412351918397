<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <div v-for="task in invoiceData.tasks" v-bind:key="task.UUID">
      <div class="name">{{ task.name }}</div>
      <div class="hours">{{ formatHours(task.thisMonthNewTime) }}</div>
      <div class="amount">{{ task.toInvoiceAmount|formatCurrency }}</div>
      <CopyButton :hide="true" color="orange" icon="mdi-currency-usd">
        <div>{{ task.toInvoiceAmount }}</div>
      </CopyButton>
      <div class="mt-5 pt-5"></div>
      <CopyButton>
        <div>
          <div v-for="(item, idx) in sortedTaskEntries(task)" v-bind:key="idx" class="items" ref="taskUUID">
            <span v-if="item">&bull; {{ item }}</span>
          </div>
        </div>
      </CopyButton>
      <br/>
    </div>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import CopyButton from "@/components/toolkit/CopyButton"

export default {
  name: "JobsInvoiceTimeSummary",
  permissions: [],
  props: ["invoiceData"],
  components: {
    BfwComponent, CopyButton
  },
  methods: {
    sortedTaskEntries(task) {
      if (!task.entries) return []
      return Array.from(task.entries)
    },
    formatHours(minutes) {
      const value = minutes / 60
      return value < 1 ? "<1 hour" : Math.round(value) + " hours"
    },

    // processTimeResponse(response) {
    //   const _this = this
    //   const localLogs = {...this.logs} // stuffs up if writing directly to this.logs (sometimes)
    //   if (response.Times) {
    //     const times = this.$ensureArray(response.Times.Time)
    //     const startDate = new Date(this.startDate + "T00:00")
    //     const endDate = new Date(this.endDate + "T00:00")
    //     endDate.setHours(23)
    //     endDate.setMinutes(59)
    //     times.forEach(timeEntry => {
    //       const entryDate = new Date(timeEntry.Date)
    //       if (startDate.getTime() <= entryDate.getTime() && entryDate.getTime() <= endDate.getTime()) {
    //         const entryLength = parseInt(timeEntry.Minutes) / 60
    //         const description = timeEntry.Note
    //         const taskUUID = timeEntry.Task.UUID
    //         _this.logs[taskUUID].totalMinutes += entryLength
    //         _this.logs[taskUUID].items.add(description)
    //       }
    //     })
    //   }
    //   this.logs = {...localLogs}
    // },
    // processTaskReviewResponse(response) {
    //   for (const idx in response.tasks) {
    //     const task = response.tasks[idx]
    //     if (this.logs[task.task_id]){
    //       this.logs[task.task_id].amount = task.amount
    //     }
    //     else {
    //       console.log("Task not found")
    //     }
    //   }
    // },


  },
  mounted() {
  },
  data() {
    return {
      title: "Job Invoice Summary",
      showTitle: false,
      editable: false,
      logs: {},
      isReady: true,
    }
  }
}

</script>

<style scoped>

.name {
  font-weight: bold
}

.hide {
  display: none
}
</style>

