<template>
  <v-app>
    <v-navigation-drawer color="primary" app touchless dark v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            BetaWorkFlow
          </v-list-item-title>
          <v-list-item-subtitle>
            <div>Making WorkflowMax Beta</div>
            <div>Version: {{ $version }}</div>
            <v-btn @click="logout" x-small color="accent">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-text-field v-model="search" label="Find" hide-details class="ml-2 mr-2"></v-text-field>
      <v-divider></v-divider>

      <v-list dense v-if="search === ''">
        <draggable :list="filteredStared" group='common' class="draggable" @end="saveOrder">
          <v-list-item v-for="item in filteredStared" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </draggable>
      </v-list>

      <v-divider></v-divider>

      <v-list dense v-if="search===''">
        <v-list-group v-for="item in filteredMenuGroups" :key="item" color="white">
          <template v-slot:activator>
            <v-list-item-title>{{ item }}</v-list-item-title>
          </template>

          <v-list-item v-for="item in menu(item)" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>

            <v-list-item-action @click.prevent="toggleStar(item)">
              <v-icon v-if="item.stared"> mdi-star</v-icon>
              <v-icon v-else> mdi-star-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list dense v-else>
          <v-list-item v-for="item in searchMenuItems" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>

            <v-list-item-action @click.prevent="toggleStar(item)">
              <v-icon v-if="item.stared"> mdi-star</v-icon>
              <v-icon v-else> mdi-star-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
      </v-list>

    </v-navigation-drawer>
    <v-app-bar app :height="toolbarHeight" v-show="showToolbar">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="./assets/logo.png" height="100%" width="300px" contain position="top left"></v-img>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="clearCache" v-bind="attrs" v-on="on">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Clear Cache</span>
      </v-tooltip>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import {menuGroups, routes} from "./routes"
import draggable from "vuedraggable"

export default {
  name: "App",
  components: {draggable},
  data() {
    return {
      drawer: null,
      showErrorMessage: false,
      menuGroups: menuGroups,
      stared: [],
      filteredStared: [],
      showToolbar: true,
      search:"",
    }
  },
  computed: {
    toolbarHeight() {
      if ("xs" === this.$vuetify.breakpoint.name || "sm" === this.$vuetify.breakpoint.name) {
        return "0"
      } else if (!this.showToolbar) {
        return "0"
      } else {
        return undefined
      }
    },
    filteredMenuGroups() {
      return menuGroups.filter(menuGroup => {
        return routes.filter(route => {
          return route.showInMenu === true && route.menuGroup === menuGroup && this.$access.pages.includes(route.name)
        }).length > 0
      })
    },
    searchMenuItems() {
      const lowerSearch = this.search.toLowerCase()
      return routes.filter(route => {
        const keywords = route.keywords && route.keywords.toLowerCase().includes(lowerSearch)
        const title = route.title && route.title.toLowerCase().includes(lowerSearch)
        return route.showInMenu === true && this.$access.pages.includes(route.name) && ( title || keywords)
      }).map(route => {
        return {
          name: route.name,
          title: route.title,
          icon: route.icon,
          to: route.path,
          stared: this.stared.includes(route.name)
        }
      })
    }
  },
  methods: {
    toggleStar(item) {
      if (this.stared.includes(item.name)) {
        const index = this.stared.indexOf(item.name)
        if (index > -1) {
          this.stared.splice(index, 1)
          item.stared = false
        }
      } else {
        this.stared.push(item.name)
        item.stared = true
      }
      this.$BwfApi.post("user-settings", {"setting_name": "stared", "setting_value": this.stared})
      this.updateFilteredStared()
    },
    updateFilteredStared() {
      this.filteredStared = routes.filter(route => {
        return route.showInMenu === true && this.$access.pages.includes(route.name) && this.stared.includes(route.name)
      }).map(route => {
        return {
          name: route.name,
          title: route.title,
          icon: route.icon,
          to: route.path,
          order: this.stared.indexOf(route.name)
        }
      }).sort((a, b) => {
        return a.order - b.order
      })
    },
    saveOrder() {
      this.stared = this.filteredStared.map(item => item.name)
      this.$BwfApi.post("user-settings", {"setting_name": "stared", "setting_value": this.stared})
    },
    clearCache() {
      this.$BwfApi.post("clear-cache").then(() => {
        this.$notify.toast("Cache Cleared")
      })
    },
    menu(menuGroup) {
      return routes.filter(route => {
        return route.showInMenu === true && route.menuGroup === menuGroup && this.$access.pages.includes(route.name)
      }).map(route => {
        return {
          name: route.name,
          title: route.title,
          icon: route.icon,
          to: route.path,
          stared: this.stared.includes(route.name)
        }
      })
    },
    logout() {
      window.location.href = "/logout"
    },
    mouseMove(event) {
      this.showToolbar = event.clientY < 30;
    }
  },
  mounted() {
    if (this.$access.expiry) { // load only if logged in
      this.$BwfApi.get("user-settings").then(settings => {
        if (settings.stared) {
          this.stared = settings.stared
        } else {
          this.stared = []
        }
        this.updateFilteredStared()
      })
    }

    document.addEventListener("mousemove", this.mouseMove)
  }
}
</script>


<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>