<template>
  <Field @save="save">
    <template v-slot:view>
      <a :href="'tel:'+mobile">{{ mobile }}</a>
    </template>
    <template v-slot:edit>
      <v-text-field v-model="mobile"></v-text-field>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"

export default {
  name: "ContactFieldMobile",
  permissions: ["Modify Contact"],
  props: ["contact", "clientId"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Contact: this.contact,
      }
      data.Contact.Mobile = this.mobile

      // filter out any empty fields
      for (const key in data.Contact) {
        if (data.Contact[key] === null) {
          data.Contact[key] = ""
        }
      }
      const uuid = data.Contact.UUID

      data.Contact.Positions = { // Plural
        Position:{
            IncludeInEmails:'Yes',
            IsPrimary:data.Contact.IsPrimary,
            UUID:this.clientId
        }
      }

      delete data.Contact.Position // singular
      delete data.Contact.IsPrimary
      delete data.Contact.UUID

      this.$WfmApi.put("client.api/contact/" + uuid, data).then((response) => this.processResponse(response))
    },
    processResponse() {
      this.$emit("updated")
    }
  },
  mounted() {
    this.mobile = this.contact.Mobile
  },
  data() {
    return {
      mobile: "",
    }
  }
}
</script>


