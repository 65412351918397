<template>
  <v-container fluid>
    <h1>Weekly Capacity Report - By Service
    <ContextHelp
        helpText="This report shows the weekly capacity plan by service, and on which project this time is allocated.
        Terminology:
        * Planned: Work that is scheduled using the capacity planning tool
        * Scheduled: Work that is scheduled in the staff plan (tasks)
        * Actual: Work that has been recorded in WorkflowMax

        The graph shows the total hours planned, scheduled and actual for each service, broken down by week.
        This can be forward-looking or used to view historical data.
        "
    />
    </h1>
    <WeeklyCapacityPlanGraphByService/>
  </v-container>
</template>

<script>
import WeeklyCapacityPlanGraphByService from "@/components/capacity/WeeklyCapacityPlanGraphByService.vue"
import ContextHelp from "@/components/toolkit/ContextHelp.vue";

export default {
  name: "ReportCapacityWeeklyByServicePagePage",
  permissions: [],
  components: {ContextHelp, WeeklyCapacityPlanGraphByService},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>