<template>
  <div class="relative">
    <v-btn @click="copy" fab x-small :color="color" absolute top right class="lighten-1">
      <v-icon>{{icon}}</v-icon>
    </v-btn>
    <slot v-if="hide===false"></slot>
  </div>
</template>


<script>
// Notes:
// * Only one component can be used in the slot, so wrapping in a div might be required
// * use :key="<some property that will change when the content changes>" to force re-render as slots are only 99% reactive

import Vue from "vue"

const {convert} = require("html-to-text")

export default {
  name: "CopyButton",
  props: {
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-clipboard-outline",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  methods: {
    copy: function () {

      // Turn the slot contents into html, then get the text out
      const DetailConstructor = Vue.extend({
        props: ["node"],
        render() {
          return this.node ? this.node : ""
        }
      })

      const detailRenderer = new DetailConstructor({
        propsData: {
          node: this.$slots.default
        }
      })

      detailRenderer.$mount()

      // covert to html string then format it
      const converted = convert(detailRenderer.$el.outerHTML, {
        wordwrap: 130
      });
      navigator.clipboard.writeText(converted)
    }
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}
</style>


