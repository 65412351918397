<template>
  <v-container fluid>
    <h1>Settings</h1>

    <v-card class="mb-4">
      <v-card-title>BetaWorkflow</v-card-title>
      <v-card-text>
        <v-text-field label="Default Billable Rate" hint="This is the default rate that will be used when the billable rate cannot be determined"
                      v-model.number="defaultBillableRate"/>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Workflow Max</v-card-title>
      <v-card-text>
        <v-select v-model="selectedCostOptions" :items="costOptions" label="Cost Importer Codes" multiple chips persistent-hint
                  hint="Which cost codes should be displayed to staff when matching transactions?"
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Xero</v-card-title>
      <v-card-text>
        <v-btn v-if="xeroStatus===false" href="/xero/connect" class="xero-connect"></v-btn>
        <v-btn v-else href="/xero/disconnect" class="xero-disconnect"></v-btn>

        <v-select v-model="selectedXeroAccountCodes" :items="options" label="Xero Account codes" multiple chips persistent-hint
                  hint="Which Xero account codes should be displayed to staff when reconciling non-project costs?"
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Google Drive</v-card-title>
      <v-card-text>
        <v-btn v-if="googleDriveStatus===false" href="/google-drive/connect">
          <v-icon>
            mdi-google-drive
          </v-icon>
          Connect Google Drive
        </v-btn>

        <v-btn v-else href="/google-drive/disconnect" target="_blank">
          <v-icon>
            mdi-google-drive
          </v-icon>
          Disconnect Google Drive
        </v-btn>

        <v-text-field label="Google Drive folder for Cost Importer" v-model="googleDriveCostImporterRoot" v-if="googleDriveStatus===true"
                      hint="This can be found by browsing to the folder in google drive and copying in the folder ID from the URL"/>

        <v-text-field label="Google Drive sheet for capacity planning" v-model="googleDriveCapacityPlanning" v-if="googleDriveStatus===true"
                      hint="This can be found by browsing to the sheet in google drive and copying in the sheet ID from the URL"/>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Lead Steps</v-card-title>
      <v-card-text>
        <div v-for="(step, idx) in leadSteps" v-bind:key="idx">
          <v-text-field v-model="leadSteps[idx]" class="step"></v-text-field>
          <v-btn color="red" @click="removeStep(idx)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <v-btn color="primary" @click="addStep">
          <v-icon>mdi-plus</v-icon>
          Add Step
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Lead Settings</v-card-title>
      <v-card-text>
        <v-text-field label="Default Time Allocation"
                      hint="This is the default time that will be allocated to tasks created from the leads system"
                      suffix='hrs'
                      v-model.number="defaultLeadsTimeAllocation"/>
      </v-card-text>
    </v-card>

    <v-btn @click="save" color="primary" class="ma-1">
      <v-icon>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>

  </v-container>
</template>

<script>

export default {
  name: "AdminSettings",
  permissions: ["Administer Betaworkflow"],
  components: {},
  data() {
    return {
      title: "Admin - Settings",
      googleDriveStatus: false,
      xeroStatus: false,
      googleDriveCostImporterRoot: "",
      googleDriveCapacityPlanning: "",
      selectedCostOptions: [],
      costOptions: [],
      options: [],
      selectedXeroAccountCodes: [],
      defaultBillableRate: 0,
      leadSteps: [],
      defaultLeadsTimeAllocation: 0,
    }
  },
  methods: {
    save() {
      const settings = {
        google_drive_cost_importer_root: this.googleDriveCostImporterRoot,
        google_drive_capacity_planning_sheet: this.googleDriveCapacityPlanning,
        cost_importer_codes: this.selectedCostOptions,
        cost_importer_xero_codes: this.selectedXeroAccountCodes,
        default_billable_rate: this.defaultBillableRate,
        lead_steps: this.leadSteps,
        default_leads_time_allocation: this.defaultLeadsTimeAllocation,
      }
      this.$BwfApi.post("admin/settings", settings).then(() => {
        this.$notify.toast("Saved")
      })
    },
    disconnect() {
      this.$BwfApi.post("google-drive/disconnect").then(response => {
        this.googleDriveStatus = response.connected
      })
    },
    loadCostOptions() {
      this.$WfmApi.get("cost.api/list", {page: 1}).then(response => {
        this.costOptions = []
        const costOptions = this.$ensureArray(response.Costs.Cost)
        for (const costOption of costOptions) {
          if (costOption.Code) {
            this.costOptions.push({text: `${costOption.Code} - ${costOption.Description}`, value: costOption.Code})
          }
        }
      })
    },
    loadXeroAccountCodeOptions() {
      this.$BwfApi.get("xero/account-codes").then(response => {
        this.options = []
        for (const xeroAccountCodeOption of response) {
          this.options.push({text: `${xeroAccountCodeOption.code} - ${xeroAccountCodeOption.name}`, value: xeroAccountCodeOption.code})
        }
      })
    },
    loadSettings() {
      this.$BwfApi.get("admin/settings").then(response => {
        this.xeroStatus = response.xero_connected
        this.googleDriveStatus = response.google_drive_connected
        this.googleDriveCostImporterRoot = response.google_drive_costs_folder_id
        this.googleDriveCapacityPlanning = response.google_drive_capacity_planning_sheet
        this.selectedCostOptions = response.cost_importer_codes
        this.selectedXeroAccountCodes = response.cost_importer_xero_codes
        this.defaultBillableRate = response.default_billable_rate
        this.leadSteps = response.lead_steps || []
        this.defaultLeadsTimeAllocation = response.default_leads_time_allocation
      })
    },
    addStep() {
      this.leadSteps.push("")
    },
    removeStep(idx) {
      this.leadSteps.splice(idx, 1)
    },
  },
  mounted() {
    this.loadSettings()
    this.loadCostOptions()
    this.loadXeroAccountCodeOptions()
  },
}

</script>

<style scoped>
.xero-connect {
  background: url('@/assets/xero-connect.svg') no-repeat;
  height: 43px !important;
  width: 190px;
}

.xero-disconnect {
  background: url('@/assets/xero-disconnect.svg') no-repeat;
  height: 43px !important;
  width: 222px;
}

.step {
  width:300px;
  display: inline-block;
}
</style>