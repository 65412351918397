<template>
  <v-container fluid>
    <h1>KPI Project Cost Variance
    <ContextHelp help-text="This page shows the aggregate project cost variance for all jobs, broken down by service and task type.
Calculation:
 * All jobs closed in the last 365 days are loaded
 * Jobs with the 'exclude' flag set are excluded
 * Each job is stepped through:
   - The task name is used to match between quote tasks, job tasks, and invoice tasks. This linkage is required because only job tasks contain the service (task type) information. If a mapping is not found, the information is excluded
   - If a job has a quote, the quote is used for the billable rate, budgeted time, and money.
   - If a job does not have a quote, the default billing rate is used, and the budgeted time and money are extracted from the estimate fields on the job.
   - The total cost for a task is taken by multiplying the billing rate by the actual time spent on the task
 * PCVs are calculated for each service as a percentage:
   - difference = difference (in dollars) between was was invoiced and the actual value of time spent on the task (as calculated above)
   - percentage = difference / actual value of time spent on the task
 * The total PCV is calculated by the same approach as the services, but by summing across all tasks irrespective of service.

Note: only services enabled on in the BWF settings page are used in the calculations."></ContextHelp>
    </h1>
    <AggregateProjectCostVariance></AggregateProjectCostVariance>
    <PCVGraph></PCVGraph>
  </v-container>
</template>

<script>
import PCVGraph from "@/components/capacity/PCVGraph.vue"
import AggregateProjectCostVariance from "@/components/jobs/AggregateProjectCostVairiance.vue"
import ContextHelp from "@/components/toolkit/ContextHelp.vue";

export default {
  name: "ReportProjectCostVariancePage",
  permissions: [
      ...PCVGraph.permissions
  ],
  components: {
    ContextHelp, AggregateProjectCostVariance,
    PCVGraph
  },
  data() {
    return {
      selectedJobId: null
    }
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>